<template>
  <article-page :menu="menu">
    <div class="training-content" id="new-app">
      <div class="_subtitle">
        Создание заявки
      </div>
      <span>
        Для создания заявки нужно перевести тендер, найденный в разделе "Поиск", в любой статус.
        Возле селектора статуса представлены две иконки для быстрого перевода без открытия селектора -
        <ui-icon :icon="UiIconNames.Icon_StarO" :size="12"/> для перевода в статус <b>"Расчет заявки (избранное)"</b>,
        <ui-icon :icon="UiIconNames.Icon_Block" :size="12"/> для перевода в статус <b>"Отказ от закупки"</b>.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        Заявкам может быть присвоен один из 9 статусов:
      </span>
      <ol>
        <li>Расчет заявки (избранное);</li>
        <li>Подача заявки;</li>
        <li>Торги;</li>
        <li>Мониторинг;</li>
        <li>Отказ от закупки;</li>
        <li>Победа;</li>
        <li>Поражение;</li>
        <li>Отклонено;</li>
        <li>Архив;</li>
      </ol>
      <span>
        Все активные заявки хранятся в разделе "Заявки". Для удобства раздел поделен на 4 подраздела:
      </span>
      <ul>
        <li><b>Все заявки</b> - все созданные заявки, независимо от установленного статуса;</li>
        <li><b>Расчет заявок</b> - заявки в статусе "Расчет заявки (избранное)";</li>
        <li><b>Подача заявок</b> - заявки в статусе "Подача заявки";</li>
        <li><b>Торги</b> - заявки в статусе "Торги";</li>
      </ul>
      <article-image :src="images[1].src" :alt="images[1].alt" @click="openViewer(images, 1)"/>
      <span>
        При переводе тендера/заявки в статус "Победа", Вам будет предложено <b>создать карточку контракта</b> согласно заявке. Подробнее о контрактах Вы можете почитать в статье
        <accessed-link :name="Tabs.Training.Contracts"> Работа с контрактами. </accessed-link>
      </span>
    </div>
    <div class="training-content" id="app-comments">
      <div class="_subtitle">
        Комментирование
      </div>
      <span>
        Аналогично
        <accessed-link :name="Tabs.Training.Comments">комментированию тендеров в поиске</accessed-link>,
        пользователи с правами на запись могут оставлять комментарии к каждой заявке. Уже написанные комментарии можно добавлять в избранное и удалять из избранного
        нажатием на иконку <ui-icon :icon="UiIconNames.Icon_StarO" :size="14" />. Для удаления комментария нажмите на иконку <ui-icon :icon="UiIconNames.Icon_Delete" :size="14" />.
      </span>
      <span>
        Обратите внимание, что комментарии, написанные в разделе заявок, видно в разделе поиска, но не видно в соответствующей карте контракта.
      </span>
      <article-image :src="images[2].src" :alt="images[2].alt" @click="openViewer(images, 2)"/>
    </div>
    <div class="training-content" id="app-responsible">
      <div class="_subtitle">
        Назначение ответственного
      </div>
      <span>
        На каждую созданную заявку можно назначить ответственного. Пользователь с правами "Администратор" или "Чтение и запись" может назначать ответственным как себя, так и других сотрудников компании
        (подробнее рассказано в статье
        <accessed-link :name="Tabs.Training.UserManagement"> Совместная работа</accessed-link>).
      </span>
      <article-image :src="images[3].src" :alt="images[3].alt" @click="openViewer(images, 3)"/>
    </div>
    <div class="training-content" id="app-notifications">
      <div class="_subtitle">
        Рассылка по событиям
      </div>
      <span>
        Пользователь может получать рассылку по событиям, связанным с его заявками (теми заявками, на которые он назначен ответственным).
        Включить и отключить рассылку можно в разделе
        <accessed-link :name="Tabs.Settings.Notifications" target="_blank">Настройки уведомлений</accessed-link>.
        Уведомления направляются по часовому поясу получателя в следующем порядке:
      </span>
      <ul>
        <li>
          <div>
            Для тендеров со статусом <b>«Подача заявки»</b>:
          </div>
          <br />
          <ol>
            <li> В выбранное время рабочего дня, предшествующего дню истечения подачи заявки; </li>
            <li> За N ч. до истечения срока подачи заявки. </li>
          </ol>
          <br />
          <div>
            Примечание: Если уведомление «за N ч.» имеет время с 23:00 до 8:00 по часовому поясу получателя, то оно не отправляется.
          </div>
        </li>
        <br />
        <li>
          <div>
            Для тендеров со статусом <b>«Торги»</b>:
          </div>
          <br />
          <ol>
            <li> За N ч. до начала аукциона; </li>
            <li> За N мин. до начала аукциона. </li>
          </ol>
          <br />
          <div>
            Примечание: Если время уведомления попадает в интервал с 23:00 до 8:00 по часовому поясу получателя, то уведомление отправляется в 17:30 предыдущего рабочего дня.
          </div>
        </li>
      </ul>
      <span>
        Для активации отправки, поставьте галочку напротив нужного уведомления (цифра 1 на картинке ниже).
      </span>
      <span>
        Время отправки уведомления можно поменять - для этого необходимо нажать на иконку
        <ui-icon :icon="UiIconNames.Icon_Cog" :size="14" /> напротив необходимого уведомления (цифра 2 на картинке ниже),
        выбрать удобное время по вашему часовому поясу и нажать кнопку <b>Сохранить</b>.
        Часовой пояс можно поменять в разделе
        <accessed-link :name="Tabs.Settings.Account" target="_blank"> Мой профиль</accessed-link>.
      </span>
      <article-image :src="images[4].src" :alt="images[4].alt" @click="openViewer(images, 4)"/>
    </div>
    <div class="training-content" id="app-calculation">
      <div class="_subtitle">
        Форма расчета
      </div>
      <span>
        Для каждой заявки Вы можете скачать excel файл, содержащий необходимую информацию для удобного расчета стоимости.
        В будущих версиях сайта планируется добавить возможность загрузки отредактированных файлов расчета.
        Это обеспечит хранение и быстрый доступ пользователей к нужным файлам.
      </span>
      <article-image :src="images[5].src" :alt="images[5].alt" @click="openViewer(images, 5)"/>
    </div>
    <div class="training-content" id="app-filters">
      <div class="_subtitle">
        Панель фильтров
      </div>
      <span>
        При открытии разделов загружаются все заявки, соответствующие разделу:
      </span>
      <ul>
        <li> Раздел "Заявки" - все созданные заявки; </li>
        <li> Подвкладка "Расчет заявки" - заявки со статусом "Расчет заявки (избранное)"; </li>
        <li> Подвкладка "Подача заявки" - заявки со статусом "Подача заявки"; </li>
        <li> Подвкладка "Торги" - заявки со статусом "Торги"; </li>
      </ul>
      <span>
        При необходимости, лишние подразделы можно скрыть в
        <accessed-link :name="Tabs.Settings.InterfaceSettings" target="_blank">Настройках функциональности</accessed-link>.
      </span>
      <span>
        Для поиска нужных заявок предусмотрено несколько вариантов фильтров и сортировок:
      </span>
      <ul>
        <li>
          <div> Селектор шаблонов (1) </div>
          <br />
          <div>
            Селектор шаблонов аналогичен селектору в поиске (см.
            <accessed-link :name="Tabs.Training.Templates">Работа с шаблонами</accessed-link>),
            однако предназначен только для фильтрации заявок.
            Выбрав шаблоны/группы шаблонов и нажав на кнопку "Применить", заявки отфильтруются в соответствии с выбранными вариантами.
            Для фильтрации так же доступен служебный шаблон "Закупки без шаблона", который содержит созданные заявки, которые не принадлежат ни одному шаблону.
          </div>
        </li>
        <br />
        <li>
          <div> Панель фильтров и сортировок (2) </div>
          <br />
          <div>
            Вам доступны фильтры и сортировки по цене, датам и статусам, а так же фильтр по номеру закупки, ключевым словам и ИНН заказчика.
            Результаты поиска можно скачать в формате excel.
          </div>
          <br />
          <div>
            Примечание: Если изменить выбор в селекторе шаблона при наличии фильтров или сортировок в панели фильтров, то панель фильтров <b>сбросится</b> до начального состояния.
          </div>
        </li>
      </ul>
      <article-image :src="images[6].src" :alt="images[6].alt" @click="openViewer(images, 6)"/>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useImageViewer } from "@/use/other/useImageViewer";
import { Tabs } from "@/router/tabs";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";

export default defineComponent({
  name: "AppsArticle",
  components: {
    AccessedLink,
    UiIcon,
    ArticlePage,
    ArticleImage,
  },
  setup() {

    const menu = [
      { id: 'new-app', title: 'Создание заявки' },
      { id: 'app-comments', title: 'Комментирование' },
      { id: 'app-responsible', title: 'Назначение ответственного' },
      { id: 'app-notifications', title: 'Рассылка по событиям' },
      { id: 'app-calculation', title: 'Форма расчета' },
      { id: 'app-filters', title: 'Панель фильтров' },
    ]

    const images = [
      { src: 'apps/new-app.png', alt: 'Создание заявки' },
      { src: 'apps/sections.png', alt: 'Раздел "Заявки"' },
      { src: 'apps/comments.png', alt: 'Комментирование заявок' },
      { src: 'apps/app-responsible.png', alt: 'Назначение ответственного' },
      { src: 'apps/app-mailing.png', alt: 'Настройка уведомлений' },
      { src: 'apps/form-calculation.png', alt: 'Скачивание формы расчета' },
      { src: 'apps/filters.png', alt: 'Поиск по заявкам' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      Tabs,
      openViewer,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
